<template>
  <v-autocomplete
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    attach
    :menu-props="{ offsetY: true, overflowY: true }"
    :loading="loading || fetching"
    :items="items"
    :clearable="clearable && !readonly"
    :readonly="readonly"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ item, index }">
      {{ item.name }}
    </template>
    <template v-slot:item="{ item, tile }">
      <v-list-item-avatar size="30" color="primary">
        <span class="caption font-weight-bold white--text">
          {{ item.name.substring(0, 2).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content class="ml-2">
        <v-list-item-title class="body-1">
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle> {{ item.driverName || null }} - {{ item.driverPhone || "-" }} </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
  import gql from "graphql-tag";

  export default {
    name: "ServiceLinePicker",
    inheritAttrs: false,
    props: {
      value: String,
      loading: Boolean,
      clearable: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    methods: {
      fetchItems() {
        const options = {
          query: gql`
            {
              serviceLines {
                id
                name
                driverName
                driverPhone
              }
            }
          `,
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.items = data.serviceLines;
            }
          })
          .catch(console.error)
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style lang="scss" scoped></style>
